<template>
    <v-card tile class="no-shadow">
        <v-card-title class="header-id pt-3 mx-0">
            <div class="header-name my-4 d-flex align-center justify-center">
                <h2>{{contact.firstname}} {{contact.name}}</h2>
            </div>
            <div class="d-flex align-center mt-3">
                <div class="mx-1" v-for="(opportunity, index) in opportunities" :key="index">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <div v-bind="attrs" v-on="on"> 
                                <span v-if="opportunity.tag === 'custom'" class="innerAlert primary bg--text">{{ opportunity.data.name }}</span>
                                <v-img v-else :height="25" :width="25" v-bind="attrs" v-on="on" :src="opportunity.picto.url"></v-img>
                            </div>
                        </template>
                        <span>{{ opportunity.tag === 'custom' ? opportunity.data.name : $t(opportunity.picto.desc) }}</span>
                    </v-tooltip>
                </div>
            </div>
        </v-card-title>
       
        <v-card-text :key="keyId">
            <v-divider></v-divider>
            <div class="d-table mt-4">
                <div class="adress mb-1" v-if='contact.type'>
                    <v-icon  color="primary" small class="mr-2 mt-0" >$contacts_d</v-icon>{{ $t('status') }} : <div class="black--text ml-1">{{ $t(contact.type) }}</div>
                </div>
                <div class="adress mb-1" v-if='contact.pro_part'>
                    <v-icon  color="primary" small class="mr-2 mt-0" >$usertie_d</v-icon>{{ $t('type') }} : <div class="black--text ml-1">{{ $t(contact.pro_part) }}</div>
                </div>
                <div v-if='contact.phones && contact.phones.length > 0' class="adress mb-1">
                    <v-icon color="primary" small class="mr-2 mt-0">$phone_d</v-icon>
                    {{ $t('phones') }} :
                    <div class="ml-1">
                        <div v-for="(phone, index) in contact.phones" :key="index">
                            <div v-if="!phone.blacklisted">
                                <a :href="'tel:' + phone.number">{{ phoneNumberToClean(phone.number) }}</a>
                            </div>
                            <div v-else>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on" class="error--text blacklistedNumber">
                                            {{ phoneNumberToClean(phone.number) }}
                                        <v-icon x-small color="error" class="mt-0 ml-2">$ban_l</v-icon>
                                        </span>
                                    </template>
                                    <span>{{phone.motif}}</span>
                                </v-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="(contact.email && contact.email != '') || (contact.email_pro && contact.email_pro != '')" class="adress mb-3">
                    <v-icon color="primary" small class="mr-2 mt-0">$at_d</v-icon>
                    {{ $t('email') }} :
                    <div class="ml-1">
                        <div v-if="contact.email && contact.email != ''">
                            <a v-if="!emailBlacklisted" :href="'mailto:' + contact.email">{{ contact.email }}</a>
                            <span v-else>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on" class="error--text blacklistedNumber">
                                            {{ contact.email }}
                                            <v-icon x-small color="error" class="mt-0 ml-2">$ban_l</v-icon>
                                        </span>
                                    </template>
                                    <span>{{ emailBlacklisted }}</span>
                                </v-tooltip>
                            </span>
                        </div>
                        <div v-if="contact.email_pro && contact.email_pro != ''">
                            <a v-if="!emailProBlacklisted" :href="'mailto:' + contact.email_pro">{{ contact.email_pro }}</a>
                            <span v-else>
                                <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on" class="error--text blacklistedNumber">
                                            {{ contact.email_pro }}
                                            <v-icon x-small color="error" class="mt-0 ml-2">$ban_l</v-icon>
                                        </span>
                                    </template>
                                    <span>{{ emailProBlacklisted }}</span>
                                </v-tooltip>
                            </span>
                        </div>
                    </div>
                </div>
                <div v-if="contact.adress_1 || contact.adress_2 || contact.adress_3 || contact.postal_code || contact.city" class="adress mb-1">
                    <v-icon color="primary" small class="mr-2 mt-0">$mapmarker_d</v-icon>
                    {{ $t('placeAdressTitle') }} :
                    <div v-if="!adressBlacklisted" class="ml-1 black--text">
                        <div v-if="contact.adress_1 != ''">{{contact.adress_1}}</div>
                        <div v-if="contact.adress_2 != ''">{{contact.adress_2}}</div>
                        <div v-if="contact.adress_3 != ''">{{contact.adress_3}}</div>
                        <div v-if="contact.postal_code || contact.city">
                            <span v-if="contact.postal_code">{{ contact.postal_code }} </span>
                            <span v-if="contact.city">{{ contact.city }}</span>
                        </div>
                    </div>
                    <div v-else class="ml-1">
                        <v-tooltip right>
                            <template v-slot:activator="{ on, attrs }">
                                <div
                                v-bind="attrs"
                                v-on="on"
                                class="error--text blacklistedNumber"
                                >
                                <div>
                                    <div v-if="contact.adress_1 != ''">{{contact.adress_1}}</div>
                                    <div v-if="contact.adress_2 != ''">{{contact.adress_2}}</div>
                                    <div v-if="contact.adress_3 != ''">{{contact.adress_3}}</div>
                                    <div v-if="contact.postal_code || contact.city">
                                        <span v-if="contact.postal_code">{{ contact.postal_code }} </span>
                                        <span v-if="contact.city">{{ contact.city }}</span>
                                    </div>
                                    <div v-if="contact.country != ''">{{contact.country}}</div>
                                </div>
                                <v-icon x-small color="error" class="mt-0 ml-2">$ban_l</v-icon>
                                </div>
                            </template>
                            <span>{{contact.adressBlacklisted}}</span>
                        </v-tooltip>
                    </div>
                </div>
                
            </div>
            <div class="mt-1">
                <v-expansion-panels>
                    <v-expansion-panel class="no-shadow">
                        <v-expansion-panel-header hide-actions class="pa-0" style="min-height:32px;">
                             <template v-slot:default="{open}">
                                <div v-if="!open">
                                    <v-icon color="purple" small>$circleplus_d</v-icon>
                                    <span class="ml-3 purple--text">{{$t('seemore')}}</span>
                                </div>
                                <div v-else>
                                    <v-icon color="purple" small>$circleminus_d</v-icon>
                                    <span class="ml-3 purple--text">{{$t('seeless')}}</span>
                                </div>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content class="pa-0 contentContactPanel">
                            <ul>
                                <li v-if="contact.comments && contact.comments != ''" class=" mb-1">
                                    <span class="grey--text">{{$t('comment')}} :</span> {{ contact.comments }}
                                </li>
                                <li v-if="contact.entity_id && contact.entity_id.id && contact.entity_id.label" >
                                    <span class="grey--text">{{$t('sellingPoint')}} : </span>
                                    <router-link v-if="this.$func.hasRight('entity/get') && contact.entity_id.id" :to="{ name: 'EntityDashboard', params: { entity_id: contact.entity_id.id }}">
                                        {{ contact.entity_id.label }}
                                    </router-link>
                                    <span v-else-if="contact.entity_id.label">
                                        {{ contact.entity_id.label }}
                                    </span>
                                    <span v-if="contact.distance_entity">
                                        ({{ Math.round(contact.distance_entity * 10) / 10 }} km)
                                    </span>
                                </li>
                                <li v-if="contact.assignment_user_id && contact.assignment_user_id.id" class=" mb-1">
                                    <span class="grey--text">{{$t('mainSeller')}} : </span>
                                    <router-link v-if="!contact.assignment_user_id.trash && this.$func.hasRight('user/get') && contact.assignment_user_id.id" :to="{ name: 'UserDetail', params: { user_id: contact.assignment_user_id.id }}">
                                        {{ contact.assignment_user_id.firstname.concat(' ', contact.assignment_user_id.name) }}
                                    </router-link>
                                    <v-tooltip v-else-if="contact.assignment_user_id.trash" top>
                                        <template v-slot:activator="{ on, attrs }">
                                            <span v-bind="attrs" v-on="on">{{ contact.assignment_user_id.firstname.concat(' ', contact.assignment_user_id.name) }}</span>
                                        </template>
                                        <span>{{ $t('userDeleted') }}</span>
                                    </v-tooltip>
                                    <span v-else>
                                        {{ contact.assignment_user_id.firstname.concat(' ', contact.assignment_user_id.name) }}
                                    </span>
                                </li>
                                <li v-if="contact.language && contact.language != ''" class=" mb-1">
                                    <span class="grey--text">{{$t('lang')}} : </span>{{ $t(contact.language) }}
                                </li>
                                <li v-if="contact.code" class=" mb-1">
                                    <span class="grey--text">{{$t('uniqueCode')}} : </span>{{ contact.code }}
                                </li>
                                <li class=" mb-1">
                                    <span class="grey--text">{{$t('reference')}} : </span> #C{{contact.id}}
                                </li>
                                <li v-for="(custom_property, index) in contact.custom_fields" :key="index" class=" mb-1">
                                    <span class="grey--text">{{$t(custom_property.reference)}} : {{custom_property.value}}</span>
                                </li>
                                <li v-if="contact.total_duration" class=" mb-1">
                                    <span class="grey--text">{{$t('total_duration')}} : </span> {{contact.total_duration}}
                                </li>
                            </ul>
                        </v-expansion-panel-content>          
                    </v-expansion-panel>
                </v-expansion-panels>
            </div>
        </v-card-text>
        <v-card-actions>
            <v-btn small v-if="this.$func.hasRight('contact/update')" class=" editButton ml-2" color="primary" depressed @click="$emit('edit')">
                {{$t('modify')}} <v-icon small class="ml-2">$next</v-icon>
            </v-btn>
            <v-btn small v-if="this.$func.hasRight('contact/update')" class=" editButton ml-2 white--text" color="black" depressed @click="blacklist">
                <v-icon small class="mr-2">$ban_d</v-icon> {{$t('blacklist')}} 
            </v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import GenericDataService from '@/services/GenericDataService'
import dateManipulation from '@/mixins/dateManipulation'

export default {
    name:"idComponent",
    props:['contact'],
    mixins: [dateManipulation],
    data() {
        return {
            publicPath: process.env.BASE_URL,
            opportunities: {},
            blacklists: [],
            keyId:0
        }
    },
    mounted() {
        if(this.$route.params.contact_id ){
            GenericDataService.getData('/contact/'+this.$route.params.contact_id+'/getAlerts').then((response) => {
                this.opportunities = response.data.data
            })
        }
       
    },
    watch: {
        contact(val){
            if(val && val.id){
                GenericDataService.getData('/contact/'+val.id+'/getAlerts').then((response) => {
                    this.opportunities = response.data.data
                })
                GenericDataService.getData('/contact/'+val.id+'/getContactBlacklist').then((response) => {
                    this.blacklists = response.data.data
                    this.contact.phones.forEach((phone, index) => {
                        this.contact.phones[index].blacklisted = this.blacklists.find(bl => bl.value === phone.number) ? true : false
                        this.contact.phones[index].motif = this.blacklists.find(bl => bl.value === phone.number) ? this.blacklists.find(bl => bl.value === phone.number).motif : false
                        this.keyId++
                    })
                })
            }
        }
    },
    methods: {
        blacklist(){
            this.$store.dispatch("contact/OPEN_BLACKLIST_FORM", true);
        }
    },
    computed: {
        adressBlacklisted(){
            return this.blacklists.find(bl => bl.value === this.contact.id) ? this.blacklists.find(bl => bl.value === this.contact.id).motif : false
        },
        emailBlacklisted(){
            return (this.contact.email && this.blacklists.find(bl => bl.value === this.contact.email)) ? this.blacklists.find(bl => bl.value === this.contact.email).motif : false
        },
        emailProBlacklisted(){
            return (this.contact.email_pro && this.blacklists.find(bl => bl.value === this.contact.email_pro)) ? this.blacklists.find(bl => bl.value === this.contact.email_pro).motif : false
        },
    }
}
</script>
<style lang="scss">
    .contentContactPanel {
        .v-expansion-panel-content__wrap {
            padding:0 !important;
        }
        ul {
            list-style:none;
            li {
                position:relative;
                .listPoint {
                    position:absolute; 
                    left:-23px; 
                    top:3px;
                }
            }
        }
    }
    
    .header-id {
        text-transform: uppercase;
        font-size:24px;
        display:flex;
        justify-content: space-between;
        //margin-left: 30px;
        .header-name {
            display:flex;
            //flex-direction:column;
            align-items: center;
            width:100%;
            h2 {
                font-size:22px;
                margin-bottom:0px;
                min-height:auto;
            }
            .v-image {
                margin-right:10px;
            }
        }
    }
    .v-card__subtitle {
        margin-left: 30px;
        display:flex;
        align-items:center;
        line-height: 1;
        @media (-webkit-device-pixel-ratio: 1.25) {
            margin-left:15px;
        }
    }
    .adress {
        display: flex;
        .v-icon {
            margin-top: 3px;
        }
    }
    .v-rating {
        height:18px;
        margin-left:20px;
        display:flex;
        align-items: center;
        button {
            margin-right:5px;
        }
    }
    .blacklistedNumber {
        display:flex;
        align-items: center;
        justify-content: flex-start;
    }
    .block {
        width:100%;
        td {
            width:50%;
        }
    }
    table {
        width:100%;
        margin-bottom:10px;
        &:last-child {
            margin-bottom:0px;
        }
    }
    .innerAlert {
        padding: 0px 4px;
        border-radius: 5px;
    }
</style>
