<template>
  <v-container fluid class="pa-6">
    <v-row>
      <v-col cols="12" v-if="operationToDisplay && operationToDisplay.id">
        <v-alert class="mb-0 mx-0 mb-2" border="left" color="info" text>
          <b>{{ operationToDisplay.name }} : </b> <span v-if='operationToDisplay.config.date_start'>Du {{parseFullDateToFr(operationToDisplay.config.date_start)}}</span> <span v-else>{{ $t('noStartingDate') }}</span> <span v-if='operationToDisplay.config.date_end'>au {{parseFullDateToFr(operationToDisplay.config.date_end)}}</span> <span v-else>{{ $t('noEndingDate') }}</span>
        </v-alert>
      </v-col>
      <v-col cols="12" md="12" lg="3">
        <ContactDetailIdentity v-if="this.$func.hasRight('contact/get')" class="mb-4" />
        <contactTodoComponent :key="contactTodoKey"/>
      </v-col>
      <v-col cols="12" md="12" lg="9">
        <contentContactComponent  ref="contentContactComponent"  @refreshTodos="refreshRdv" :key="'contactContent'+contactContentKey" @editPassage="editPassage" @addPassage="addPassage" @editRdv="editMeeting" @showPlanning="showPlanning"/>
      </v-col>
    </v-row>
    <v-dialog width="600" class="rounded-lg" scrollable persistent v-model="openBlackListForm">
      <v-card>
          <v-toolbar tile flat dark class="bg-gradient">
              <v-btn depressed color="transparent" class="square mr-2" @click.native="openBlackListForm = false"><v-icon dark small>$prev</v-icon></v-btn>
              <v-toolbar-title>{{$t('BlackListFormTitle')}}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn outlined dark depressed @click.native="closeBL">{{$t("cancelLabel")}}</v-btn>
              <v-btn outlined dark depressed @click="validateBL" class="ml-3">{{$t("recordLabel")}}</v-btn>
          </v-toolbar>
          <v-card-text class="pt-3">
            <v-form ref="blacklistForm" v-model="valid">
              <div v-for="(checkbox, index) in blacklistItems" :key="'check_'+index">
                <v-checkbox
                  v-model="checkbox.value"
                  :label="checkbox.label"
                  dense
                  color="primary"
                  hide-details
                ></v-checkbox>
                <v-textarea
                  filled
                  required
                  height="100"
                  :label="$t('motiveBlacklistLabel')"
                  :rules="[validateMotive]"
                  v-if="checkbox.value"
                  v-model="checkbox.motive"
                  class="mt-3 required"
                ></v-textarea>
              </div>
            </v-form>
          </v-card-text>
      </v-card>
    </v-dialog>
    <PlanningDialogComponent
      ref="componentPlanning"
      :type="planningType"
      :contact_id="$route.params.contact_id "
      :requestForRdv="requestForRdv"
      :operationId="operationId"
      :operations="operations"
      :teamPlannings='teamPlannings'
      :vendeurId="vendorId"
      :appointements="appointements"
      :contact="contact"
      :key="'planningDialog_'+planningKey"
      v-if="operationId != 0"
    />
    <RdvFormDialogComponent :key="'rdvForm_'+rdvComponentKey" :appeal_id="$route.params.todo_id ? $route.params.module_id : null" @close="closeDialogRdv" @refreshRdv="refreshRdv" @editDatePlace="editDatePlace" :contact="contact" :openRdvDialog="openRdvDialog" :operationId="operationId" :multi="teamPlannings" :rdvRegistered="rdvRegistered" :rdvInfo="rdvInfo" :title="titleRdvPopup"/>
    <addContactItemDialogComponent @refresh="refreshAfterAdd" />

    <v-dialog v-model="dialogPassage" width="900" :key="passageKey">
      <v-card>
      <v-toolbar tile flat dark  class="bg-gradient">
          <v-toolbar-title>{{$t('addPassage')}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn depressed small @click="openDeletePassage" color="error">
              {{$t('delete')}}
          </v-btn>
          <v-btn outlined dark small depressed @click="confirmPassage" class="ml-3">{{$t('recordButton')}}</v-btn>
          <v-btn depressed text color="white" class="square ml-3" @click.native="cancelPassage">
              <v-icon>$close</v-icon>
          </v-btn>
      </v-toolbar>
      <v-card-text>
        <div class="mt-6">
          <v-select :items="placeList" @change='changePlace' required return-object class="required" v-model="placeSelected" filled dense :label="$t('choosePlaceLabel')" v-if="placeList.length > 1" :no-data-text="$t('no-data-text')"></v-select>
          <p v-else-if="placeList.length == 1 && passage && passage.place && (passage.place.text || passage.place.config.name)" >
            Lieu : {{passage.place.text ? passage.place.text : passage.place.config.name}}
          
          </p>
          <v-select :items="vendorListFiltered" v-model="passage.vendor" filled dense :label="$t('chooseVendorLabel')" solo flat class="input-form" :no-data-text="$t('no-data-text')"></v-select>
          <VDatetimePicker :hideDetails="true" :field="{title:'date_start', props: {required:true}}" :fieldName="'date_start'" :model="passage"  />
          <span v-if="errorStartPassage != ''" class="error--text">{{$t(errorStartPassage) }}</span>
          <VDatetimePicker class="mt-2" :hideDetails="true"  :field="{title:'date_end', props: {required:true}}" :fieldName="'date_end'" :model="passage"  />
          <span v-if="errorEndPassage != ''" class="error--text">{{$t(errorEndPassage) }}</span>
            <v-textarea v-model="passage.comment" :label="$t('comment')" class="input-form mt-2" solo flat filled hide-details></v-textarea>
            <!-- <v-text-field dense filled v-model="passage.comment" :label="$t('comment')"></v-text-field> -->
           
        </div>
      </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeletePassage" width="400">
            <v-card>
                <v-toolbar tile flat dark  :style="'background-image:url('+publicPath+'img/blue-background.jpg); background-size:cover;'">
                    <v-toolbar-title>{{$t('cancelPassage')}}</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn outlined dark small depressed @click.native="closeCancelPassageDialog">{{$t('cancel')}}</v-btn>
                    <v-btn depressed dark small color="error" @click="deletePassage" class="ml-3">{{$t('delete')}}</v-btn>
                </v-toolbar>
                <v-card-text>
                    <div class="mt-6">
                        <v-form v-model="validCancel" ref="cancelForm">
                            <v-textarea v-model="cancelReason" class="mt-2 required" :label="$t('cancelReason')" required filled 
                            :rules="[v => (v != '') ? true : $t('cancelReasonError')]"></v-textarea>
                        </v-form>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    <v-dialog v-model="dialogInscription" width="900">
    <v-card>
          <v-toolbar tile flat dark  :style="'background-image:url('+publicPath+'img/blue-background.jpg); background-size:cover;'">
              <v-toolbar-title>{{$t('addInscription')}}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn outlined dark depressed @click.native="cancelInscription">{{$t('cancel')}}</v-btn>
              <v-btn outlined dark depressed @click="confirmInscription" class="ml-3">{{$t('recordButton')}}</v-btn>
          </v-toolbar>
          <v-card-text>
            <div class="mt-6">
              <v-select :items="placeList" required return-object class="required" v-model="placeSelected" filled dense :label="$t('choosePlaceLabel')" v-if="placeList.length > 1" :no-data-text="$t('no-data-text')"></v-select>
              <p v-else-if="placeList.length == 1 && inscription && inscription.place && inscription.place.config.name" ><b>{{$t('placeInscription')}} : </b>{{inscription.place.config.name}}</p>
              <p v-if="inscription && inscription.date_start"><b>{{$t('dateStartInscription')}} : </b>{{parseFullDateToFr(inscription.date_start)}}</p>
              <p v-if="inscription && inscription.date_end"><b>{{$t('dateEndInscription')}} : </b>{{parseFullDateToFr(inscription.date_end)}}</p>
              <span>Nombre de personnes</span>
              <span class="icons-Acc ml-2">
                  <v-icon dense v-for="(accomp, index) in 5" :key="index" @click="setAccompagnant(index)" :color="index < inscription.accompagnants ? 'success' : ''" class="mr-1 iconUser">{{inscription.accompagnants > index ? '$user_s' : '$user_l'}}</v-icon>
              </span>
              <v-textarea v-model="inscription.comment" class="mt-2" :label="$t('comment')" filled hide-details></v-textarea>
            </div>
            
          </v-card-text>
    </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import contactTodoComponent from '../contactsComponents/contactTodoComponent.vue';
import contentContactComponent from '../contactsComponents/contentContactComponent.vue';
import addContactItemDialogComponent from '../../../components/dialogs/addContactItemDialogComponent.vue';
import FormDialogComponent from "@/components/dialogs/FormDialogComponent";
import ContactDetailItems from "@/modules/contacts/views/ContactDetailItems";
import ContactDetailPoints from "@/modules/contacts/views/ContactDetailPoints";
import ContactDetailIdentity from "@/modules/contacts/views/ContactDetailIdentity";
import PlanningDialogComponent from '@/components/dialogs/PlanningDialogComponent'
import RdvFormDialogComponent from '@/components/dialogs/RdvFormDialogComponent'
import GenericDataService from '@/services/GenericDataService'
import refreshData from "@/mixins/mixins";
import dateManipulation from '@/mixins/dateManipulation'
import VDatetimePicker from "@/components/forms/inputs/v-datetime-picker";


export default {
  name: "ContactDetail",
  mixins: [refreshData, dateManipulation],
  components: {
    FormDialogComponent,
    ContactDetailItems,
    ContactDetailPoints,
    ContactDetailIdentity,
    PlanningDialogComponent,
    RdvFormDialogComponent,
    VDatetimePicker,
    contentContactComponent,
    contactTodoComponent,
    addContactItemDialogComponent
  },
  data() {
    return {
      contactContentKey: 0,
      publicPath: process.env.BASE_URL,
      blacklistMotive: "",
      valid:false,
      blacklistItems:[],
      apiUrl: "/contact/get?id=" + this.$route.params.contact_id,
      mutation: "contact/setContactData",

      //DATA Planning
      planningType: "",
      requestForRdv: [],
      operationId:0,
      operations:[],
      teamPlannings: [],
      vendorId:'all',
      appointements: [],
      //DATA RDV
      openRdvDialog:false,
      rdvRegistered: {},
      rdvInfo: {},
      titleRdvPopup: "",
      rdvComponentKey:0,
      planningKey: 0,
      //DATA Passage
      dialogPassage:false,
      errorStartPassage: "",
      errorEndPassage: "",
      passage: {},
      passageJsDate: {},
      teamList:[],
      placeListFull:[],
      placeList : [],
      vendorListFiltered: [],
      placeSelected: {},
      dialogDeletePassage: false,
      validCancel:false,
      cancelReason: "",
      //DATA inscription
      dialogInscription: false,
      inscription: {},
      passageKey:0,
      contactTodoKey:0,
      operationToDisplay: {},
    };
  },
  // destroyed() {
  //   this.$store.dispatch("base/SET_HEADER_ACTIONS", {});
  // },
  beforeRouteLeave (to, from, next) {
        this.$store.dispatch('base/SET_HEADER_ACTIONS', {});
        this.operationToDisplay = {};
        next();
  },
  created() {
     if(this.$route.params.addRdv){
       this.$store.dispatch('SET_LOADER_DASHBOARD', true)
       let operation;
       let demand;
       let demand_id = this.$route.params.demand_id ? this.$route.params.demand_id : this.$route.params.module_id
       GenericDataService.getData('/demand/'+demand_id+'/get').then((response) => {
         demand = response.data.data
         GenericDataService.getData('/operation/'+this.$route.params.operation_id+'/get').then((response) => {
           operation = response.data.data
          
           this.showPlanning(operation, [demand])
           setTimeout(() => {
               this.$store.dispatch('SET_LOADER_DASHBOARD', false)
           }, 250);
         })
       })
     }

    if(this.$route.query && this.$route.query.script_rp) {
        this.$store.dispatch("auth/SET_OPEN_CALL_SCRIPT_DRAWER", { value: true, rpId: this.$route.query.script_rp });
        GenericDataService.getData('retroplanning/'+this.$route.query.script_rp+'/get').then((response) =>{
          let operation_id = response.data.data.operation_id.id;
          GenericDataService.getData('/operation/'+operation_id+'/get').then((response) => {
            this.operationToDisplay = response.data.data;
             // Permet de mettre l'url propre
            this.$router.replace({'query': null});
          })
        })  
    }
    if(this.apiUrl)
        this.refreshData()
  },
  watch: {
    todo: {
          deep:true,
          handler(val){
              if(!val.hasOwnProperty('id')){
                  setTimeout(() => {
                    this.contactTodoKey++
                  }, 100);
              }   
          }
          
      },
    contact(newVal) {
      this.initBLItems();
      if(this._.isEmpty(newVal)){
        this.$router.push({name: "Error404"})
      }

      // Ajout des subActions
      if(newVal.actions && newVal.actions.get) {
        newVal.actions.get.forEach(element => element.url ? element.url = element.url.replace("{{id}}", this.$route.params.contact_id) : '');
        this.$store.dispatch("base/SET_HEADER_ACTIONS", newVal.actions.get);
      }
    },
    $route(to, from) {
      this.apiUrl = "/contact/get?id=" + this.$route.params.contact_id.id ? this.$route.params.contact_id.id : this.$route.params.contact_id;
      console.log(this.apiUrl);
      if(this.$route.params.addRdv)
        this.addRdvFromTodo()
      if(this.apiUrl)
        this.refreshData()
    },
    addRdvState(val){
      if(val){
        this.addRdvFromTodo()
      }
    },
    refreshContactTodo(val){
      if(val){
        this.contactTodoKey++
        this.contactContentKey++
        this.refreshContactTodo = false
      }
    }
  },
  methods: { 
    addRdvFromTodo(){
      this.$store.dispatch('SET_LOADER_DASHBOARD', true)
       let operation;
       let demand;
       let demand_id = this.$route.params.demand_id ? this.$route.params.demand_id : this.$route.params.module_id
       if(!demand_id){
          demand_id = this.$route.query.demand
       }
       GenericDataService.getData('/demand/'+demand_id+'/get').then((response) => {
         demand = response.data.data
         let operation_id = demand.operation_id.id ? demand.operation_id.id : demand.operation_id
         GenericDataService.getData('/operation/'+operation_id+'/get').then((response) => {
           operation = response.data.data
            this.addRdvState = false,
           this.showPlanning(operation, [demand])
           setTimeout(() => {
               this.$store.dispatch('SET_LOADER_DASHBOARD', false)
           }, 250);
         })
       })
    },
    refreshAfterAdd(module, response){
      if(module == 'demand'){
          this.$store.dispatch('contact/SET_TAB_ID', 0)
          this.$router.push({query: {demand: response.id}})
      } else {
        this.contactContentKey++
        this.$store.dispatch('contact/SET_TAB_ID', 1)
      } 
      this.contactTodoKey++
    },
    deletePassage(){
       if(this.$refs.cancelForm.validate()){
          this.passage.type = "PASSAGE"
          this.passage.is_closed = 1
          this.passage.closed_reason = this.cancelReason
          this.passage.status = "CANCELLED"
          GenericDataService.postData('/rdv/set', this.passage).then((response) => {
              this.rdvComponentKey++
              this.$refs.demandsComponent.customRefresh()
              this.cancelReason = ""
              this.dialogDeletePassage = false
              this.dialogPassage = false
              this.contactTodoKey++
          })
       }
    },
    closeCancelPassageDialog(){
        this.cancelReason = ""
        this.dialogDeletePassage = false
    },
    openDeletePassage(){
        this.dialogDeletePassage = true
    },
    setAccompagnant(index){
            if(this.inscription.accompagnants == index +1){
                this.inscription.accompagnants = 0
            } else {
                this.inscription.accompagnants = index + 1
            }
            this.$forceUpdate()
        },
    addInscription(operation){
        GenericDataService.getData('/operation/'+operation.id+'/get').then((response) => {
            let operationPlaces = response.data.data.places
            this.placeListFull = operationPlaces
            let placeArray = []
            operationPlaces.forEach(place => {
                let placeItem = {}
                placeItem.text = place.config.name
                placeItem.value = place.id
                placeArray.push(placeItem)
            });
            this.placeList = placeArray
            this.inscription.date_start = response.data.data.config.date_start
            this.inscription.date_end = response.data.data.config.date_end
            this.placeSelected = placeArray[0]
            this.inscription.accompagnants = 0
            this.inscription.comment = ""
            this.inscription.place = this.placeListFull.find((e)=> e.id == placeArray[0].value)
            this.inscription.operation_id = operation.id
            //user_id
            if(this.$store.state.auth.currentUser.loggedAs){
              this.inscription.user_id = this.$store.state.auth.currentUser.loggedAs.id
            } else {
              this.inscription.user_id = this.$store.state.auth.currentUser.profile.id
            }
            this.inscription.type = "INSCRIPTION"
            this.inscription.links = {}
            this.inscription.links.contact = [parseInt(this.$route.params.contact_id)]
            this.dialogInscription = true
        })
        this.contactTodoKey++
    },
    editInscription(meeting){
      GenericDataService.getData('/operation/'+meeting.operation_id+'/get').then((response) => {
          let operationPlaces = response.data.data.places
          this.placeListFull = operationPlaces
          let placeArray = []
          operationPlaces.forEach(place => {
              let placeItem = {}
              placeItem.text = place.config.name
              placeItem.value = place.id
              placeArray.push(placeItem)
          });
          this.placeList = placeArray
          this.inscription = meeting
          this.placeSelected = this.placeList.find((e) => e.value == meeting.place.id)
          this.dialogInscription = true
          this.contactTodoKey++
      })
    },
    cancelInscription() {
      this.$refs.demandsComponent.customRefresh()
      this.dialogInscription = false
    },
    confirmInscription() {
      this.inscription.status = "CONFIRMED"
      this.inscription.place = this.placeListFull.find((e) => e.id == this.placeSelected.value)
      GenericDataService.postData('/rdv/set', this.inscription).then((response) => {
          this.rdvComponentKey++
          this.$refs.demandsComponent.customRefresh()
          this.dialogInscription = false
      })
    },
    initBLItems() {
      //change gestion de la blacklist en passant par le service
      GenericDataService.getData('/contact/'+this.contact.id+'/getContactBlacklist').then((response) => {
          this.contact.blacklist = response.data.data
          this.getBlacklistItems();
          //Remapping des téléphones BL
          this.contact.phones.forEach(element => {
                let blPhone = this.contact.blacklist.find((e) => e.value == element.number)
                if(blPhone){
                    element.blacklisted = true;
                    element.motif = blPhone.motif;
                }
          });
          //Ajout de propriété de BL sur le contact si adresse BL
          let addressBL = this.contact.blacklist.find((e) => e.type == "ADDRESS")
          if(addressBL){
              this.contact.adressBlacklisted = addressBL.motif
          }
          //Ajout de propriété de BL sur le contact si email BL
          let emailBL = this.contact.blacklist.find((e) => e.type == "EMAIL" && e.value == this.contact.email)
          if(emailBL){
              this.contact.emailBlacklisted = emailBL.motif
          }
          //Ajout de propriété de BL sur le contact si email_pro BL
          let emailProBL = this.contact.blacklist.find((e) => e.type == "EMAIL" && e.value == this.contact.email_pro)
          if(emailProBL){
              this.contact.emailProBlacklisted = emailProBL.motif
          }
      })
        
        
    },
    validateBL(){
      if(this.$refs.blacklistForm.validate()){
        let payload = {}
        payload.fields = this.blacklistItems
        payload.entity_id = this.contact.entity_id.id

        GenericDataService.postData('/blacklist/_set', payload).then((response) => {
          this.refreshData()
          this.openBlackListForm = false;
        } )
      }
      
    },
    closeBL(){
      this.openBlackListForm = false
      this.refreshData();
    },
    validateMotive(val) {
      if(!val || val == ""){
        return this.$t('MotiveBlacklistRequired')
      } else {
        return true
      }
    },
    getBlacklistItems(){
        let items = []
        GenericDataService.getData('library/getLibTypes').then((response) => {
            let libTypes = Object.keys(response.data.data);

            if(libTypes.includes('EMAIL') && this.contact.email && this.contact.email != '') {
                let blEmail = this.contact.blacklist.find((e) => e.type == "EMAIL" && e.value == this.contact.email)
                let item = {
                    label:this.contact.email,
                    type:"EMAIL",
                    value: blEmail ? blEmail.value : false,
                    motive: blEmail ? blEmail.motif : ""
                }
                items.push(item)
            }
            if(libTypes.includes('EMAIL') && this.contact.email_pro && this.contact.email_pro != ''){
                let blEmailPro = this.contact.blacklist.find((e) => e.type == "EMAIL" && e.value == this.contact.email_pro)
                let item = {
                    label:this.contact.email_pro,
                    type:"EMAIL",
                    value: blEmailPro ? blEmailPro.value : false,
                    motive: blEmailPro ? blEmailPro.motif : ""
                }
                items.push(item)
            }
            if(['SMS', 'MMS', 'VMS', 'TEL'].filter(value => libTypes.includes(value)).length && this.contact.phones && this.contact.phones.length > 0){
                this.contact.phones.forEach(element => {
                    let blPhone = this.contact.blacklist.find((e) => e.type == "PHONE" && e.value == element.number)
                    let item = {
                        label: element.number,
                        type:"PHONE",
                        value: blPhone ? blPhone.value : false,
                        motive: blPhone ? blPhone.motif : ""
                    }
                    items.push(item)
                });
            }
            if(libTypes.includes('MAILING')) {
                let blAdress = this.contact.blacklist.find((e) => e.type == "ADDRESS" && e.value == this.contact.id)
                let item = {
                    label: this.$t("adressBlacklistLabel"),
                    type:"ADDRESS",
                    contact: this.contact.id,
                    value: blAdress ? true : false,
                    motive: blAdress ? blAdress.motif : ""
                }
                items.push(item)
            }
        });
     
      this.blacklistItems = items
    },
    //RDVS
    showPlanning(operation, requestForRdv) {
      this.operations = []
      //Définition des props pour le planning
      this.planningType = "ADD_RDV_REQUEST"
      this.operationId = operation.id
      //Demandes cliquées
      this.requestForRdv = requestForRdv
      GenericDataService.getData('/operation/'+this.operationId+'/get').then((response) => {
        this.operations.push(response.data.data)      
        //get les teamPlannings 
        GenericDataService.getData('/operation/'+(requestForRdv[0].operation_id.id ? requestForRdv[0].operation_id.id : requestForRdv[0].operation_id)+"/getTeamPlannings?withStats=1").then( async (response) => {
          if(!response || !response.data || !response.data.data || !response.data.data.data){
            this.$store.dispatch('ADD_ERROR', {errorMessage: "NoTeamPlanningSetted", type: 'user', link:{name:"OperationsDetailTeam", params:{operation_id: (requestForRdv[0].operation_id.id ? requestForRdv[0].operation_id.id : requestForRdv[0].operation_id)}}})
          } else {
            this.teamPlannings = response.data.data.data

            if(this.contact.assignment_user_id && this.contact.assignment_user_id.id && this.teamPlannings.filter((e) => e.id == this.contact.assignment_user_id.id).length > 0){
              let contactVendorId = this.contact.assignment_user_id.id
              this.vendorId = contactVendorId
            } else {
              if(response.data.data.data.filter((e) => e.id == this.$store.state.auth.currentUser.id).length > 0){
                this.vendorId = this.$store.state.auth.currentUser.id
              } else {
                  this.vendorId = "all"
              }
            }
            

            
            if(this.teamPlannings && this.teamPlannings.length > 0){
              let vm = this;
              //On lance la popup planning
              await this.$refs.componentPlanning.open().then(function (response) {
                if(response.method == "addRdv"){
                  //préenregistrement du rdv : construction des datas (différent entre BDD et front planning)
                  let rdvToRecord = {}
                  rdvToRecord.type = "RDV"
                  rdvToRecord.date_start = response.rdv.date+" "+response.rdv.start
                  rdvToRecord.date_end = response.rdv.date+" "+response.rdv.end
                  rdvToRecord.operation_id = vm.operationId
                  if(vm.$store.state.auth.currentUser.loggedAs){
                    rdvToRecord.user_id = vm.$store.state.auth.currentUser.loggedAs.id
                  } else {
                    rdvToRecord.user_id = vm.$store.state.auth.currentUser.profile.id
                  }
                  rdvToRecord.place = response.rdv.place
                  rdvToRecord.links = {}
                  rdvToRecord.links.demand = vm.requestForRdv.map((e) => parseInt(e.id))
                  rdvToRecord.links.contact = [parseInt(vm.$route.params.contact_id)]
                  if(response.rdv.ref_id){
                    rdvToRecord.links.user = [parseInt(response.rdv.ref_id)]
                  }
                  vm.titleRdvPopup = "addRdvForRequestTitle"
                  vm.rdvInfo = response.rdv
                  vm.rdvInfo.demands = [];
                  vm.rdvInfo.demands = vm.requestForRdv
                  vm.rdvInfo.visio = 0;
                  vm.appointements.push(vm.rdvInfo)
                  GenericDataService.postData('/rdv/set?addHistory=true'+(vm.$route.params.todo_id ? '&appeal_id='+vm.$route.params.module_id : ''), rdvToRecord).then((response) => {
                    vm.rdvRegistered = response.data.data
                    GenericDataService.getData('/contact/'+ vm.$route.params.contact_id + '/get').then((response) => {
                      vm.rdvInfo.contact = {}
                      vm.rdvInfo.contact.text = response.data.data.firstname+" "+response.data.data.name
                      vm.rdvInfo.contact.value = response.data.data.id
                      vm.openRdvDialog = true
                    })
                  })
                }
              });
            }
          }
        })
      })
    },
     editMeeting(meeting){
      //this.$emit('editRdv', meeting)
      this.planningType = "EDIT_RDV_REQUEST"
      this.operationId = meeting.operation_id
      this.operations = []
      this.rdvInfo.id = meeting.id
      this.rdvInfo.date = meeting.date_start.split(' ')[0]
      this.rdvInfo.start = meeting.date_start.split(' ')[1]
      this.rdvInfo.end = meeting.date_end.split(' ')[1]
      if(meeting.links && meeting.links.user && meeting.links.user[0]){
         this.rdvInfo.ref_id = meeting.links.user[0]
         this.vendorId = meeting.links.user[0]
      }
      this.rdvInfo.place = meeting.place
      this.rdvInfo.accompagnants = meeting.accompagnants
      this.rdvInfo.visio = meeting.visio
      this.rdvInfo.ressource = meeting.ressource
      this.rdvRegistered = this._.cloneDeep(meeting)
      
      GenericDataService.getData('/operation/'+this.operationId+'/get').then((response) => {
          this.operations.push(response.data.data)
          GenericDataService.getData('/contact/'+meeting.links.contact[0]+'/get').then((response) => {
            this.rdvInfo.contact = {
              text: response.data.data.firstname+" "+response.data.data.name,
              value: response.data.data.id
            }
            this.rdvInfo.demands = []
            for (let index = 0; index < meeting.links.demand.length; index++) {
              const demandId = meeting.links.demand[index];
              GenericDataService.getData('/demand/'+demandId+'/get').then((response) => {
                this.rdvInfo.demands.push(response.data.data)
                if(index == meeting.links.demand.length - 1){
                  GenericDataService.getData('/operation/'+this.operationId+"/getTeamPlannings?withStats=1").then((response) => {
                    this.teamPlannings = response.data.data.data
                    this.rdvInfo.ref = this.teamPlannings.find((e) => e.id == this.rdvInfo.ref_id)
                    this.appointements.push(this.rdvInfo)
                    this.titleRdvPopup = "editRdvTitle"
                    this.rdvKey++
                    this.planningKey++
                    setTimeout(() => {
                        this.openRdvDialog = true
                    }, 100);
                  })
                }
              })
            }
          })
      })
    },
    //Edition de la date ou du vendeur : réouverture du planning
    async editDatePlace(){
        this.planningType = "EDIT_RDV_DATE_REQUEST" //changement de type de planning 
        if(this.rdvInfo.ref_id)
          this.vendorId = this.rdvInfo.ref_id 
        let vm = this;
        
        await this.$refs.componentPlanning.open().then(function (response) {
            if(response && response.rdv){
                vm.rdvRegistered.date_start = response.rdv.date+" "+response.rdv.start
                vm.rdvRegistered.date_end = response.rdv.date+" "+response.rdv.end
                vm.rdvRegistered.place = response.rdv.place

                vm.rdvInfo.start = response.rdv.start
                vm.rdvInfo.end = response.rdv.end
                vm.rdvInfo.date = response.rdv.date
                vm.rdvInfo.place = response.rdv.place
                if(new Date().getTime() < new Date(vm.rdvRegistered.date_start).getTime() || vm.rdvRegistered.status == 'CONFIRMED'){
                    vm.rdvRegistered.status = "DRAFT"
                }
                if(response.rdv.ref_id != vm.rdvRegistered.links.user){
                  vm.rdvRegistered.links.user = [parseInt(response.rdv.ref_id)]
                  vm.rdvInfo.ref_id = response.rdv.ref_id
                  vm.rdvInfo.ref = vm.teamPlannings.find((e) => e.id == response.rdv.ref_id) 
                }


                
                GenericDataService.postData('/rdv/set', vm.rdvRegistered).then((response) => {
                  vm.contactTodoKey++
                  vm.rdvComponentKey++
                  vm.contactContentKey++
                })
            }
            
          });
    },
    closeDialogRdv(){
      if(this.planningType == "ADD_RDV_REQUEST"){
        //TODO: suppression du rdv quand on créé et on annule
        GenericDataService.postData('/rdv/'+this.rdvRegistered.id+'/deleteRdv').then((e) => {
          this.contactTodoKey++
          this.contactContentKey++
        })
      }
      this.refreshRdv()
    },
    resetRdvForm(){
      this.planningType = "",
      this.requestForRdv = [],
      this.operationId = 0,
      this.teamPlannings = [],
      this.vendorId = 0,
      this.appointements = [],

      this.openRdvDialog = false,
      this.rdvRegistered = {},
      this.rdvInfo = {},
      this.titleRdvPopup = "",
      this.rdvComponentKey = 0
    },
    changePlace(){
      this.vendorListFiltered = this.teamList.filter((e) => e.places.includes(this.placeSelected.value))
    },
    cancelPassage(){
      this.dialogPassage = false
    },
    confirmPassage(){
      this.errorStartPassage = ""
      this.errorEndPassage = ""
        // this.passage.date_start = this.parseJsDateToDatetimeSql(this.passageJsDate.date_start)
        // this.passage.date_end = this.parseJsDateToDatetimeSql(this.passageJsDate.date_end)
        if(this.passage.date_end && this.passage.date_start){
          if(this.passage.vendor){
            this.passage.links.user = [parseInt(this.passage.vendor)]
          }
          this.passage.status = "CONFIRMED"
          this.passage.place = this.placeListFull.find((e) => e.id == this.placeSelected.value)
          //user_id
          if(this.$store.state.auth.currentUser.loggedAs){
            this.passage.user_id = this.$store.state.auth.currentUser.loggedAs.id
          } else {
            this.passage.user_id = this.$store.state.auth.currentUser.profile.id
          }
          this.passage.type = "PASSAGE"
          GenericDataService.postData('/rdv/set', this.passage).then((response) => {
              this.rdvComponentKey++
              this.contactTodoKey++
              //this.contactContentKey++;
              this.$refs.contentContactComponent.refreshInteractions()
              this.dialogPassage = false
          })
        } else {
          if(!this.passage.date_end)
            this.errorEndPassage = "enddateFieldsAreRequired"
          if(!this.passage.date_start)
            this.errorStartPassage = "startdateFieldsAreRequired"
        }
        
    },
    addPassage(request , operation, team, places){
      this.errorStartPassage = ""
      this.errorEndPassage = ""
      this.passage = {}
      this.placeListFull = operation.places
      this.placeList = places
      // this.passageJsDate.date_start = new Date(operation.config.date_start)
      // this.passageJsDate.date_end = new Date(operation.config.date_end)
      this.passage.operation_id = operation.id
      this.placeSelected = this.placeList[0]
      this.passage.place = this.placeList[0]
      if(operation.config.date_start){
        this.passage.date_start = operation.config.date_start
      } else {
        this.passage.date_start = ""
      }
        
      if(operation.config.date_end){
        this.passage.date_end = operation.config.date_end
      } else {
        this.passage.date_end = ""
      }
        
      this.passage.comment = "";
      this.passage.links = {}
      this.passage.links.demand = [parseInt(request.id)]
      this.passage.links.contact = [parseInt(this.$route.params.contact_id)]
      team.forEach(person => {
           let teamListObject = {}
           teamListObject.text = person.firstname+" "+person.name
           teamListObject.value = person.id
           teamListObject.places = person.places.map((e) => e.id)
           this.teamList.push(teamListObject)
      });
      this.passageKey++;
      
      this.vendorListFiltered = this.teamList.filter((e) => e.places.includes(this.passage.place.value))
      setTimeout(() => {
           this.dialogPassage = true
      }, 150);
     
    },
    refreshRdv(){
      this.openRdvDialog = false
      this.resetRdvForm()
      this.$refs.contentContactComponent.refreshInteractions()
      this.contactTodoKey++
      this.contactContentKey++
    },
    editPassage(meeting){
      GenericDataService.getData('/operation/'+meeting.operation_id+'/get').then((response) => {
            let operationPlaces = response.data.data.places
            this.placeListFull = operationPlaces
            let placeArray = []
            operationPlaces.forEach(place => {
                let placeItem = {}
                placeItem.text = place.config.name
                placeItem.value = place.id
                placeArray.push(placeItem)
            });
            this.placeList = placeArray
            GenericDataService.getData('/operation/'+meeting.operation_id+'/getTeamPlannings').then((response) => {
                let team = response.data.data.data
                this.teamList = []
                team.forEach(person => {
                    let teamListObject = {}
                    teamListObject.text = person.firstname+" "+person.name
                    teamListObject.value = person.id
                    teamListObject.places = person.places.map((e) => e.id)
                    this.teamList.push(teamListObject)
                });
                this.vendorListFiltered = this.teamList.filter((e) => e.places.includes(meeting.place.id))
                this.passage = meeting
                this.passage.vendor = ''+this.passage.vendor
                this.placeSelected = this.placeList.find((e) => e.value == meeting.place.id)
                if(meeting.links && meeting.links.user && meeting.links.user.length > 0){
                    this.vendorId = meeting.links.user[0]
                    this.passage.vendor = ''+meeting.links.user[0]
                }
                
                // this.passageJsDate.date_start = new Date(this.passage.date_start)
                // this.passageJsDate.date_end = new Date(this.passage.date_end)

                this.dialogPassage = true
            })
      })
      
    }
  },
  computed: {
    todo(){
            return this.$store.state.auth.todoForDrawer
        },
    addRdvState: {
      get: function(){
        return this.$store.state.contact.addRdv;
      },
      set: function(val){
        this.$store.dispatch("contact/SET_ADD_RDV", false);
      }
    },
    refreshContactTodo: {
      get: function(){
        return this.$store.state.contact.refreshContactDetailTodo;
      },
      set: function(val){
        this.$store.dispatch("contact/REFRESH_CONTACTDETAIL_TODO", false);
      }
    },
    openBlackListForm: {
      get: function(){
        return this.$store.state.contact.blacklistForm;
      },
      set: function(val){
        this.$store.dispatch("contact/OPEN_BLACKLIST_FORM", false);
      }
    },
    contact: function () {
      return this.$store.state.contact.contact;
    },
  },
};
</script>

<style lang="scss">
</style>